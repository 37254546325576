
/**
 * This service is responsible for managing user's associate wishes.
 * It provides methods to fetch, update, and manipulate data related to the associate wishes.
 * The service interacts with a backend service or API to retrieve and persist data, ensuring data consistency across sessions.
 * It maintains an observable of the user's associate wishes, allowing other parts of the application to react to changes in the data.
 * Additionally, it provides methods to format and aggregate wishes for easier data handling.
 * Error handling is built into data fetching and updating methods to ensure smooth user experience even when issues occur.
 */

import { Injectable } from '@angular/core';
import { BackApiService } from '../../services/back-api/back-api.service';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, EMPTY, Subscription, combineLatest } from 'rxjs';
import { map, tap, catchError, take, switchMap } from 'rxjs/operators';
import { AlertController } from '@ionic/angular';
import { ProfilService } from '../profil/profil.service';

@Injectable({
  providedIn: 'root'
})


export class AssociateService {
  myAssociateWishes: any = null;
  myAssociateWishesObs: BehaviorSubject<any> = new BehaviorSubject(null);
  myAssociateWishesSubscribtion: Subscription | null = null;
  userRole: string | null = null;
  userUid: string | null = null;

  constructor(
    private alertController: AlertController,
    private backApiService: BackApiService,
    private profilService: ProfilService) {
    console.log('ASSOCIATE_S constructor()');
    this.profilService.getuserRoleObs().subscribe(role => {
      this.userRole = role;
    });
    this.profilService.getuserUidObs().subscribe(userUid => {
      this.userUid = userUid;
    });
  }


  /**
   * Initializes or updates the data associated with the user's associate wishes.
   * Unsubscribes from any existing subscriptions to avoid memory leaks.
   * Fetches data from a backend service, handling any errors that may occur during the process.
   * @returns {void}
   */
  initMyAssociateWishes() {
    console.log('ASSOCIATE_S initMyAssociateWishes()');
    console.log(`${environment.associateWishes}`);
    if (this.myAssociateWishesSubscribtion) {
      this.myAssociateWishesSubscribtion.unsubscribe();
    }
    this.myAssociateWishesSubscribtion = this.backApiService.getData(`${environment.associateWishes}`, true).subscribe((res: any) => {
      console.log('ASSOCIATE_S initMyAssociateWishes() res retourned =');
      console.log(res);
      if (res?.["member"]?.[0]) {
        this.reformatWishes(res["member"][0]);
        this.myAssociateWishesObs.next(res["member"][0]);
      } else {
        this.myAssociateWishesObs.next('empty');
        console.log("ASSOCIATE_S initMyAssociateWishes() empty")
      }
    }, error => {
      console.log("ASSOCIATE_S initMyAssociateWishes() res returned error");
      this.showAlert("Impossible de récuperer les information sur votre recherche d'associés. Verifiez votre connexion ou réessayez plus tard");
    });
  }

  /**
   * Returns an observable stream of the user's associate wishes.
   * Useful for subscribing to and reacting to changes in the associate wishes.
   * @returns {Observable} Observable stream of the user's associate wishes.
   */
  getMyAssociateWishesObs() {
    return this.myAssociateWishesObs.asObservable();
  }

  /**
 * TO BE REMOVED IN DECEMBER 2023 WHEN ALL WISHES WILL BE REFORMATED IN BACK AND MOBILES APPS UPDATED
 * @param wishes 
 * @returns 
 * 
 */
  reformatWishes(wishes: any) {
    console.log("ASSOCIATE_S reformatWishes() wishes =");
    console.log(wishes);
    console.log(this.userRole);
    if (!wishes.projectTypes?.[0]) {
      return;
    }
    wishes.projectTypes.forEach((res: any) => {
      if (res.name == 'practicing_partner') {
        res.name = 'practicing_partner_search';
      } else if (res.name == 'investment_partner' && this.userRole == 'ROLE_CANDIDATE') {
        res.name = 'investment_partner_search';
      } else if (res.name == 'solo') {
        res.name = 'solo_installation_search';
      } else if (res.name == 'investment_partner' && this.userRole == 'ROLE_RECRUITER') {
        res.name = 'pharmacy_invest';
      }
    });
  }

  /** ""
   * Updates the current associate wishes observable with a new value.
   * @param {object} wishes The new wishes to update the observable with.
   * @returns {void}
   */
  setMyAssociateWishesObs(wishes: any) {
    this.myAssociateWishesObs.next(wishes);
  }

  /**
   * Retrieves a specific associate wish by its unique identifier (uid).
   * Handles any errors that occur during data retrieval.
   * @param {string} uid The unique identifier of the associate wish to retrieve. 
   * @returns {Observable} Observable stream of the requested associate wish.
   */
  getAssociateWishe(uid: string | null = null) {
    console.log('ASSOCIATE_S getAssociateWishe()');
    console.log(uid);
    return this.backApiService.getData(`${environment.associateWishes}/${uid}`, true).pipe(
      tap((res: any) => {
        console.log('ASSOCIATE_S getAssociateWishe() res retourned =');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("ASSOCIATE_S getAssociateWishe() res returned error");
        this.showAlert("Impossible de récuperer les informations sur la recherche d'associés. Verifiez votre connexion ou réessayez plus tard");
        throw e;
      }));
  }


  /**
   * Updates a specific associate wish by its unique identifier (uid).
   * Handles any errors that occur during the update process.
   * @param {string} uid The unique identifier of the associate wish to update.
   * @param {any} wishe The updated data for the associate wish.
   * @returns {Observable} Observable stream of the updated associate wish.
   */
  putAssociateWishe(uid: string, wishe: any) {
    console.log('ASSOCIATE_S putAssociateWishes()');
    console.log(uid);
    if (wishe.hasProject == 'true') {
      wishe.hasProject = true;
    } else if (wishe.hasProject == 'false') {
      wishe.hasProject = false;
    }
    return this.backApiService.putData(`${environment.associateWishes}/${uid}`, wishe, true, false).pipe(
      map((res: any) => {
        console.log('ASSOCIATE_S putAssociateWishes() res retourned =');
        console.log(res);
        this.setMyAssociateWishesObs(res);
        return res;
      }),
      catchError(e => {
        console.log("ASSOCIATE_S putAssociateWishes() res returned error");
        this.showAlert("Impossible de modifier les les informations sur votre recherche d'associés. Vérifiez votre connexion ou réessayez plus tard.");
        throw e;
      }));
  }


  /**
   * Creates a new associate wish by making a POST request to the backend service.
   * Handles any errors that occur during the creation process.
   * @param {any} wishe The new associate wish to be created.
   * @returns {Observable} Observable stream of the newly created associate wish.
   */
  postAssociateWishe(wishe: any) {
    console.log('ASSOCIATE_S postAssociateWishe()');
    if (wishe.hasProject == 'true') {
      wishe.hasProject = true;
    } else if (wishe.hasProject == 'false') {
      wishe.hasProject = false;
    }
    return this.backApiService.postData(`${environment.associateWishes}`, wishe, true, false).pipe(
      map((res: any) => {
        console.log('ASSOCIATE_S postAssociateWishe() res retourned =');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("ASSOCIATE_S postAssociateWishe() res returned error");
        this.showAlert("Impossible de sauvegarder les les informations sur votre recherche d'associés. Vérifiez votre connexion ou réessayez plus tard.");
        throw e;
      }));
  }

  /**
   * Get all proposes of a user
   * @returns {Observable} Observable stream of the user proposes
   */
  getProposes() {
    console.log('ASSOCIATE_S getProposes()');
    return this.backApiService.getData(`${environment.associatePropose}`, true).pipe(
      tap((res: any) => {
        console.log('ASSOCIATE_S getProposes() res retourned =');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("ASSOCIATE_S getProposes() res returned error");
        this.showAlert("Impossible de récuperer les informations sur les proposition. Verifiez votre connexion ou réessayez plus tard");
        throw e;
      }));
  }

  /**
   * Get a propose by its unique identifier (uid)
   * @param {string} uid The unique identifier of the propose to retrieve.
   * @returns {Observable} Observable stream of the requested propose.
   */
  getPropose(uid: string | null = null) {
    console.log('ASSOCIATE_S getPropose()');
    console.log(uid);
    return this.backApiService.getData(`${environment.associatePropose}/${uid}`, true).pipe(
      tap((res: any) => {
        console.log('ASSOCIATE_S getPropose() res retourned =');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("ASSOCIATE_S getPropose() res returned error");
        this.showAlert("Impossible de récuperer les informations sur la proposition. Verifiez votre connexion ou réessayez plus tard");
        throw e;
      }));
  }

  /**
   * Creates a new propose by making a POST request to the backend service.
   * Handles any errors that occur during the creation process.
   * @param {any} propose The new propose to be created.
   * @returns {Observable} Observable stream of the newly created propose.
   */
  putPropose(uid: string, propose: any) {
    console.log('ASSOCIATE_S putPropose()');
    console.log(propose);
    return this.backApiService.putData(`${environment.associatePropose}/${uid}`, propose, true, false).pipe(
      map((res: any) => {
        console.log('ASSOCIATE_S putPropose() res retourned =');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("ASSOCIATE_S putPropose() res returned error");
        this.showAlert("Impossible de modifier les informations sur la proposition. Vérifiez votre connexion ou réessayez plus tard.");
        throw e;
      }));
  }

  /**
  * Display Error
  * @param {string} msg Error message
  */
  showAlert(msg: string = "", title: string = "Erreur") {
    let alert = this.alertController.create({
      message: msg,
      header: title,
      buttons: ['OK']
    });
    alert.then(alert => alert.present());
  }

  getTransactionSearch(filters: any = null, page: number = 1, itemsPerPage: number = 10) {
    console.log('ASSOCIATE_S getTransactionSearch() start');
    console.log(filters);
    let formatedFilters = '';
    if (filters) {
      console.log('ASSOCIATE_S getTransactionSearch() filters =');
      console.log(filters);
      console.log(JSON.parse(JSON.stringify(filters)));
      if (filters?.zones?.[0]?.latitude) {
        console.log('ASSOCIATE_S getTransactionSearch() filters.zones[0].latitude =');
        console.log(filters.zones[0].latitude);
        formatedFilters = formatedFilters + '&latitude=' + filters.zones[0].latitude;
        formatedFilters = formatedFilters + '&longitude=' + filters.zones[0].longitude;
        formatedFilters = formatedFilters + '&radius=' + filters.zones[0].radius;
      } else if (filters?.zones?.[0]?.zipcode) {
        formatedFilters = formatedFilters + '&city=' + filters.zones[0].city;
        formatedFilters = formatedFilters + '&zipcode=' + filters.zones[0].zipcode;
        formatedFilters = formatedFilters + '&radius=' + filters.zones[0].radius;
      }
      if (filters?.projectTypes?.[0]) {
        let project_types: Array<any> = [];
        filters.projectTypes.forEach((res: any) => {
          project_types = project_types.concat(this.getWisheMatchs(res));
        });
        project_types = project_types.filter((v, i, a) => a.indexOf(v) === i);
        project_types.forEach((res: any) => {
          formatedFilters = formatedFilters + '&projectType[]=' + res;
        });
      }
      if (filters?.projectTypesToSearch?.[0]) {
        filters.projectTypesToSearch.forEach((res: any) => {
          formatedFilters = formatedFilters + '&project_type[]=' + res;
        });
      }
      if (filters.isProject) {
        formatedFilters = formatedFilters + '&is_project=true';
      }
      if (filters.userUid) {
        formatedFilters = formatedFilters + '&userUid=' + filters.userUid;
      }
      //  formattedFilters = Object.keys(filters).map(key => key + '=' + filters[key]).join('&');
    }
    return this.backApiService.getData(`${environment.transactionSearch}?page=${page}&itemsPerPage=${itemsPerPage}${formatedFilters}`, true).pipe(
      map((res: any) => {
        console.log('ASSOCIATE_S getTransactionSearch() res retourned =');
        console.log(res);
        if (res?.['member']?.[0]) {
          this.addTransactionRoles(res);
          return res;
        } else {
          return [];
        }
      }),
      catchError(e => {
        console.log("ASSOCIATE_S getTransactionSearch() res returned error");
        this.showAlert("Impossible de récuperer les informations sur les transactions. Verifiez votre connexion ou réessayez plus tard");
        throw e;
      }));
  }

  addTransactionRoles(res: any) {
    res['member'].forEach((candidate: any) => {
      if (candidate.projectTypes[0] == 'solo_installation_search' || candidate.projectTypes[0] == 'practicing_partner_search' || candidate.projectTypes[0] == 'investment_partner_search') {
        candidate.projectTypes.unshift('pharmacy_buying');
        candidate.transactionRoles = 'pharmacien';
        if (candidate.gender == 'female') {
          candidate.transactionRoles = 'pharmacienne';
        }
      } else {
        if (candidate.projectTypes.includes('gradual_exit') || candidate.projectTypes.includes('shares_resale') || candidate.projectTypes.includes('pharmacy_selling')) {
          candidate.transactionRoles = 'vendeur';
          if (candidate.gender == 'female') {
            candidate.transactionRoles = 'vendeuse';
          }
        }
        if (candidate.projectTypes.includes('pharmacy_invest')) {
          if (candidate.transactionRoles == 'vendeur') {
            candidate.transactionRoles = 'vendeur et investisseur';
            if (candidate.gender == 'female') {
              candidate.transactionRoles = 'vendeuse et investisseuse';
            }
          } else {
            candidate.transactionRoles = 'investisseur';
            if (candidate.gender == 'female') {
              candidate.transactionRoles = 'investisseuse';
            }
          }
        }
      }
    });
  }

  getWisheMatchs(wishe: string) {
    console.log('ASSOCIATE_S getWisheMatchs()');
    console.log(wishe);
    switch (wishe) {
      case 'pharmacy_selling':
        return ['practicing_partner_search', 'investment_partner_search', 'solo_installation_search', 'pharmacy_invest', 'pharmacy_buying'];
      case 'investment_partner_search':
        return ['investment_partner_search'];
      case 'practicing_partner_search':
        return ['practicing_partner_search'];
      case 'pharmacy_invest':
        return ['pharmacy_invest'];
      case 'pharmacy_buying':
        return ['gradual_exit', 'pharmacy_selling', 'shares_resale'];
      case 'shares_resale':
        return ['shares_resale'];
      // case 'gradual_exit':
      //   return ['practicing_partner_search', 'solo_installation_search', 'pharmacy_invest', 'pharmacy_buying'];
      default:
        return [];
    }
  }

  postProject(project: any) {
    console.log('ASSOCIATE_S postTransactionOffer()');
    console.log(project);
    return this.backApiService.postData(`${environment.transactionProjects}`, project, true, false).pipe(
      map((res: any) => {
        console.log('ASSOCIATE_S postProject() res retourned =');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("ASSOCIATE_S postProject() res returned error");
        this.showAlert("Impossible de sauvegarder les informations sur l'offre de transaction. Vérifiez votre connexion ou réessayez plus tard.");
        throw e;
      }));
  }

  putProject(uid: string, project: any) {
    console.log('ASSOCIATE_S putProject()');
    console.log(project);
    return this.backApiService.putData(`${environment.transactionProjects}/${uid}`, project, true, false).pipe(
      map((res: any) => {
        console.log('ASSOCIATE_S putProject() res retourned =');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("ASSOCIATE_S putProject() res returned error");
        this.showAlert("Impossible de modifier les informations sur l'offre de transaction. Vérifiez votre connexion ou réessayez plus tard.");
        throw e;
      }));
  }

  getMyProjects(filters: any = null, page: number = 1, itemsPerPage: number = 10) {
    console.log('ASSOCIATE_S getMyProjects()');
    let formatedFilters = '';

    if (filters && filters.status && filters.status != "") {
      if (filters.status == "archived") {
        formatedFilters += '&status[]=closed&status[]=cancel';
      } else {
        formatedFilters += '&status=' + encodeURIComponent(filters.status);
      }
    }

    const url = `${environment.transactionProjects}?page=${page}&itemsPerPage=${itemsPerPage}${formatedFilters}`;

    return this.backApiService.getData(url, true).pipe(
      map((res: any) => {
        console.log('ASSOCIATE_S getMyProjects() res retourned =');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("ASSOCIATE_S getMyProjects() res returned error");
        this.showAlert("Impossible de récuperer les informations sur vos offres de transaction. Vérifiez votre connexion ou réessayez plus tard");
        throw e;
      })
    );
  }

  getProject(uid: string, addJwtToken: boolean = true) {
    console.log('ASSOCIATE_S getProject()');
    return this.backApiService.getData(`${environment.transactionProjects}/${uid}`, addJwtToken).pipe(
      map((res: any) => {
        console.log('ASSOCIATE_S getProject() res retourned =');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("ASSOCIATE_S getProject() res returned error");
        this.showAlert("Impossible de récuperer les informations sur l'offre de transaction. Verifiez votre connexion ou réessayez plus tard");
        throw e;
      })
    );
  }

  logEvent(projectUid: string, logType: string) {
    if (!projectUid || !logType) {
      return;
    }
    console.log('ASSOCIATE_S logEvent()');
    let body: any = {
      project: {
        uuid: projectUid
      }
    };
    return this.backApiService.postData(`${environment.transactionLogs}/${logType}`, body, true, false).pipe(
      map((res: any) => {
        console.log('ASSOCIATE_S logEvent() res retourned =');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("ASSOCIATE_S logEvent() res returned error");
        //   this.showAlert("Impossible de sauvegarder les informations sur l'événement. Vérifiez votre connexion ou réessayez plus tard.");
        throw e;
      }));
  }

  postFavorite(projectOrWish: any) {
    if (!projectOrWish?.uuid) {
      console.warn('projectUid is undefined or null. Returning EMPTY Observable.');
      return EMPTY; // Retourne un Observable vide
    }
    console.log('ASSOCIATE_S postFavorite()');
    let body: any = {
    };
    let url = '';
    if (projectOrWish?.isProject || projectOrWish?.projectType) {
      url = environment.transactionProjectFavorites;
      body.project = {
        uuid: projectOrWish?.uuid
      }
    } else {
      url = environment.transactionWishFavorites;
      body.wish = {
        uuid: projectOrWish?.uuid
      }
    }
    return this.backApiService.postData(`${url}`, body, true, false).pipe(
      map((res: any) => {
        console.log('ASSOCIATE_S postFavorite() res returned =');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("ASSOCIATE_S postFavorite() res returned error");
        this.showAlert("Impossible de sauvegarder les informations sur le favoris. Vérifiez votre connexion ou réessayez plus tard.");
        throw e;
      })
    );
  }

  deleteFavorite(projectOrWish: any) {
    if (!projectOrWish?.favoriteUuid) {
      console.warn('favoriteUid is undefined or null. Returning EMPTY Observable.');
      return EMPTY; // Retourne un Observable vide
    }
    console.log('ASSOCIATE_S deleteFavorite()');
    console.log(!projectOrWish?.uuid);
    let url = '';
    if (projectOrWish?.isProject || projectOrWish?.projectType) {
      url = environment.transactionProjectFavorites;
    } else {
      url = environment.transactionWishFavorites;
    }
    return this.backApiService.deleteData(`${url}`, `${projectOrWish?.favoriteUuid}`).pipe(
      map((res: any) => {
        console.log('ASSOCIATE_S deleteFavorite() res returned =');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("ASSOCIATE_S deleteFavorite() res returned error");
        this.showAlert("Impossible de supprimer les informations sur le favoris. Veuillez réessayez plus tard puis contacter le support.");
        throw e;
      })
    );
  }

  getProjectStats(projectUid: string, nbDays: number = 60, intervalLastDay: string = new Date().toISOString().split('T')[0]) {
    if (!projectUid) {
      console.warn('projectUid is undefined or null. Returning EMPTY Observable.');
      return EMPTY; // Retourne un Observable vide
    }
    console.log('ASSOCIATE_S getProjectStats()');
    return this.backApiService.getData(`${environment.transactionProjects}/${projectUid}/statistics?days=+${nbDays}&intervalLastDay=${intervalLastDay}`, true).pipe(
      map((res: any) => {
        console.log('ASSOCIATE_S getProjectStats() res returned =');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("ASSOCIATE_S getProjectStats() res returned error");
        this.showAlert("Impossible de récuperer les informations sur les statistiques de l'offre. Veuiillez réessayez plus tard puis contacter le support.");
        throw e;
      })
    );
  }

  generateProjectDescription(project: any) {
    console.log('ASSOCIATE_S generateProjecttDescription()');
    console.log(project);
    return this.backApiService.postData(`${environment.transactionGenerateDescription}`, project, true, false).pipe(
      map((res: any) => {
        console.log('ASSOCIATE_S generateProjecttDescription() res returned =');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("ASSOCIATE_S generateProjecttDescription() res returned error");
        this.showAlert("Impossible de générer la description de l'offre. Veuillez réessayez plus tard puis contacter le support.");
        throw e;
      })
    );
  }

  getProjectViews(projectUid: string) {
    console.log('ASSOCIATE_S getProjectViews()');
    return this.backApiService.getData(`${environment.transactionLogs}/${projectUid}/views`, true).pipe(
      map((res: any) => {
        console.log('ASSOCIATE_S getProjectViews() res returned =');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("ASSOCIATE_S getProjectViews() res returned error");
        this.showAlert("Impossible de récuperer les informations sur les vues de l'offre. Veuiillez réessayez plus tard puis contacter le support.");
        throw e;
      })
    );
  }

  getProjectFavorites(projectUid: string) {
    console.log('ASSOCIATE_S getProjectFavorites()');
    return this.backApiService.getData(`${environment.transactionProjectFavorites}/${projectUid}/project-favorites`, true).pipe(
      map((res: any) => {
        console.log('ASSOCIATE_S getProjectFavorites() res returned =');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("ASSOCIATE_S getProjectFavorites() res returned error");
        this.showAlert("Impossible de récuperer les informations sur les favoris de l'offre. Veuiillez réessayez plus tard puis contacter le support.");
        throw e;
      })
    );
  }

  getWishFavorites(projectUid: string) {
    console.log('ASSOCIATE_S getWishFavorites()');
    return this.backApiService.getData(`${environment.transactionWishFavorites}/${projectUid}/wish-favorites`, true).pipe(
      map((res: any) => {
        console.log('ASSOCIATE_S getWishFavorites() res returned =');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("ASSOCIATE_S getWishFavorites() res returned error");
        this.showAlert("Impossible de récuperer les informations sur les favoris de l'offre. Veuiillez réessayez plus tard puis contacter le support.");
        throw e;
      })
    );
  }



  getProjectPhoneDisplays(projectUid: string) {
    console.log('ASSOCIATE_S getProjectPhoneDisplays()');
    return this.backApiService.getData(`${environment.transactionLogs}/${projectUid}/phone-displays`, true).pipe(
      map((res: any) => {
        console.log('ASSOCIATE_S getProjectPhoneDisplays() res returned =');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("ASSOCIATE_S getProjectPhoneDisplay() res returned error");
        this.showAlert("Impossible de récuperer les informations sur les affichages de téléphone de l'offre. Veuiillez réessayez plus tard puis contacter le support.");
        throw e;
      })
    );
  }

  getProjectCreatedThreads(projectUid: string) {
    console.log('ASSOCIATE_S getProjectCreatedThreads()');
    return this.backApiService.getData(`${environment.transactionLogs}/${projectUid}/thread-creations`, true).pipe(
      map((res: any) => {
        console.log('ASSOCIATE_S getProjectCreatedThreads() res returned =');
        console.log(res);
        return res;
      }),
      catchError(e => {
        console.log("ASSOCIATE_S getProjectCreatedThreads() res returned error");
        this.showAlert("Impossible de récuperer les informations sur les discussions créées de l'offre. Veuiillez réessayez plus tard puis contacter le support.");
        throw e;
      })
    );
  }

  formatFavorites(projectFavorites: any[], wishFavorites: any[]) {
    const formattedFavorites = [];

    // Traiter les favoris de projets
    if (projectFavorites && projectFavorites.length > 0) {
      for (const favorite of projectFavorites) {
        const project = favorite.project;
        if (project) {
          const locations = [{
            region: project.address?.locality || '',
            departement: project.address?.departement || ''
          }];

          const additionalData = {
            photos: project.pharmacySelling?.photos || [],
            isBoosted: false,
            salesPrice: project.pharmacySelling?.salesPrice || 0,
            teamDetails: project.pharmacySelling?.teamDetails || {},
            keyStrengths: project.pharmacySelling?.keyStrengths || '',
            isIntermediate: project.isIntermediate || false,
            isAddressHidden: false,
            propertyDetails: project.pharmacySelling?.propertyDetails || {},
            financialOverview: project.pharmacySelling?.financialOverview || {},
            companyEnvironment: project.pharmacySelling?.companyEnvironment || '',
            contactPhoneNumber: project.contactPhoneNumber || {},
            additionalInformation: project.pharmacySelling?.additionalInformation || '',
            nearbyMedicalServices: project.pharmacySelling?.nearbyMedicalServices || ''
          };

          formattedFavorites.push({
            id: project.id,
            uuid: project.uuid,
            isProject: true,
            gender: project.gender || 'male',
            firstname: project.firstname,
            lastname: project.lastname,
            title: project.title || '',
            description: project.description || '',
            projectTypes: [project.projectType],
            locations: locations,
            messageReactivity: 0,
            messageResponseRate: 0,
            proposeReactivity: 0,
            additionalData: additionalData,
            favoriteUuid: favorite.uuid,
            updatedAt: project.updatedAt || project.createdAt,
            lastActivity: new Date().toISOString()
          });
        }
      }
    }

    // Traiter les favoris de souhaits
    if (wishFavorites && wishFavorites.length > 0) {
      for (const favorite of wishFavorites) {
        const wish = favorite.wish;
        if (wish) {
          const formattedLocations = wish.locations?.map((location: any) => ({
            region: location.address?.locality || '',
            departement: location.address?.departement || ''
          })) || [];

          // Déterminer le genre pour la photo de profil
          const gender = wish.gender || 'male';

          formattedFavorites.push({
            id: wish.id,
            uuid: wish.uuid,
            isProject: false,
            gender: gender,
            firstname: wish.firstname || '',
            lastname: wish.lastname || '',
            title: wish.title || '',
            description: wish.description || '',
            projectTypes: wish.projectTypes.map((pt: any) => pt.name || pt),
            locations: formattedLocations,
            messageReactivity: 0,
            messageResponseRate: 0,
            proposeReactivity: 0,
            toComplete: wish.toComplete,
            favoriteUuid: favorite.uuid,
            updatedAt: wish.updatedAt || wish.createdAt,
            lastActivity: new Date().toISOString(),
            photoPath: this.getRandomImg(gender) // Ajout de la photo de profil
          });
        }
      }
    }

    // Trier les favoris par date de création (les plus récents en premier)
    formattedFavorites.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());

    return {
      items: formattedFavorites,
      totalItems: (projectFavorites?.length || 0) + (wishFavorites?.length || 0)
    };
  }

  getRandomImg(gender: string = 'male') {
    const randomNumber = Math.random();
    if (randomNumber < 0.7) {
      const randomIndex = Math.floor(Math.random() * 78);
      return `https://xsgames.co/randomusers/assets/avatars/${gender}/${randomIndex}.jpg`;
    }
    return "./assets/imgs/avatar.png";
  }

  getWishAndProjectFavorites(page: number = 1, itemsPerPage: number = 10) {
    console.log('ASSOCIATE_S getWishAndProjectFavorites()');

    return combineLatest([
      this.backApiService.getData(`${environment.transactionProjectFavorites}?page=${page}&itemsPerPage=${itemsPerPage}`, true),
      this.backApiService.getData(`${environment.transactionWishFavorites}?page=${page}&itemsPerPage=${itemsPerPage}`, true)
    ]).pipe(
      map(([projectFavorites, wishFavorites]: any) => {
        console.log('ASSOCIATE_S getWishAndProjectFavorites() results =', projectFavorites, wishFavorites);

        const projectMembers = projectFavorites?.member || [];
        const wishMembers = wishFavorites?.member || [];

        return this.formatFavorites(projectMembers, wishMembers);
      }),
      catchError(e => {
        console.log("ASSOCIATE_S getWishAndProjectFavorites() res returned error");
        this.showAlert("Impossible de récupérer vos favoris. Vérifiez votre connexion ou réessayez plus tard.");
        throw e;
      })
    );
  }

}