import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SlugifyService {

  constructor() { }

  slugify(text: string | undefined | null): string {
    if (!text) return '';

    return text
      .toString()
      .normalize('NFKD')            // Normalize Unicode characters
      .toLowerCase()
      .trim()                       // Trim whitespace from both ends
      .replace(/\s+/g, '-')         // Replace spaces with -
      .replace(/[^\w\-]+/g, '')     // Remove all non-word chars
      .replace(/\-\-+/g, '-')       // Replace multiple - with single -
      .replace(/^-+/, '')           // Trim hyphens from start
      .replace(/-+$/, '');          // Trim hyphens from end
  }
}